export default defineNuxtRouteMiddleware(async (to, from) => {
	const { authenticated } = useAuthStore();

	if (authenticated && to.path !== '/features-under-development') {
		return navigateTo("/features-under-development");
	}

	if (!authenticated && to.path !== '/login') {
		return navigateTo("/login");
	}
})